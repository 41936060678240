.institutional {
    font-family: 'Open Sans', sans-serif;
}

.institutional-heading {
    color: #0D292F;
    font-size: 2.5rem;
    margin-bottom: 1em;
}

.institutional-content {
    padding: 1em 0;
}

.institutional-text-content {
    color: #43494e;
}
