@media only screen and (max-width: 768px) {
    .product-question-text-input,
    .product-question-submit{
        width: 100%;
        margin-bottom: 1em;
    }
}

@media only screen and (min-width: 769px) {
    .product-question-text-input{
        flex: 1;
        padding-right: 1em;
    }
    .product-question-submit{
        width: 200px; flex: 0 0 200px
    }
}

.product-question-icon{
    width: 25px; flex: 0 0 25px
}

.product-answer{
    flex: 1;
}
