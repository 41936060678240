header {
    background: #000;
}

.header-search-input {
    border: 0;
    border-radius: 25px;
    font-family: Montserrat;
    font-weight: 500;
    color: #444;
}

.header-search-input::placeholder {
    color: #444;
    opacity: 1;
}