.footer__top-area__heading {
    font-family: Montserrat;
    font-weight: 500;
    color: #FFC004;
}

.footer-newsletter__button {
    background: #FFC004;
    border: 0;
    font-family: Montserrat;
    font-weight: 700;
    border-radius: 30px;
}

.footer-newsletter__input {
    font-family: Montserrat;
    color: #000;
    text-align: right;
    border-radius: 30px;
}

.footer-newsletter__input::placeholder {
    color: #000;
    opacity: 1;
}