.product-box,
.product-box-showcase {
    background: #fff;
    border-radius: 5px;
}

.product-box-image-link {
    position: relative;
    width: 100%;
}

.product-box {
    width: 100%;
}

.product-box-image-link img {
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.product-box-link {
    font-family: Montserrat;
    font-weight: 400;
    color: #000 !important;
}

.product-box-price {
    width: 100%;
}

.spotlight-product-price {
    font-family: Montserrat;
    color: #000;
    font-weight: 700;
}

.product-box-price small {
    color: #000;
    font-weight: 300;
    font-size: 13px;
    display: flex;
    justify-content: center;
    align-items: baseline;
    flex-flow: wrap;
    column-gap: 2px;
}

.product-box-price small del,
.product-box-price small b {
    font-weight: 700;
}

.product-box-price small.product-box-business-model {
    background:#FFC004;
    display: table;
    color: #000;
    font-weight: 700;
    border-radius: 18px;
}