body {
    background: #eee;
}

ul,
ul li {
    margin: 0;
}

small {
    line-height: 1.1;
}

.col-xs-25,
.col-sm-25,
.col-md-25,
.col-lg-25 {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}

.col-xs-25 {
    width: 20%;
    float: left;
}

.row:before,
.row:after {
    display: none !important;
}

select {
    background-color: #fff;
    -moz-appearance: none;
    -webkit-appearance: textfield;
}

@media (min-width: 768px) {
    .col-sm-25 {
        width: 20%;
        float: left;
    }
}

@media (min-width: 992px) {
    .col-md-25 {
        width: 20%;
        float: left;
    }
}

@media (min-width: 1200px) {
    .col-lg-25 {
        width: 20%;
        float: left;
    }
}