.product-description-heading {
    font-family: Montserrat;
    font-weight: 600;
    color: #000;
}

.product-title {
    font-family: Montserrat;
    color: #F7BA04;
    font-weight: 700;
}

.product-store-supplier,
.product-store-supplier a {
    font-family: Montserrat;
    font-weight: 400;
    color: #000;
}

.product-price small {
    font-family: Montserrat;
    color: #000;
    font-weight: 400;
    display: block;
}

.product-price small b {
    font-weight: 700;
}

.product-price-current {
    font-family: Montserrat;
    color: #F7BA04;
    font-weight: 700;
}

.product-price small.product-business-model {
    background: #F7BA04;
    color: #000;
    text-align: center;
    font-weight: 400;
}

.variant-select {
    border: 0;
    border-radius: 20px;
    font-family: Montserrat;
    font-weight: 400;
    background: #fff;
    color: #444;
    border: 0;
}

.variant-select[type="number"] {
    text-align: center;
}

.product-buy-action {
    background: #4CD104;
    border: 0;
    border-radius: 40px;
    font-family: Montserrat;
    color: #fff;
    font-weight: 700;
}

.select-arrow {
    color: #F7BA04;
    font-size: 16px;
    right: 11px;
    z-index: 2;
    font-weight: 600;
}

.product-share-hd {
    font-family: Montserrat;
    font-weight: 400;
    color: #000;
}

.product-share li {
    overflow: hidden;
    position: relative;
}

.product-share li a {
    width: 100%;
    height: 100%;
    display: block;
}

.product-share li a img {
    width: 100%;
    height: 100%;
}

.product-share li span {
    width: 100% !important;
    height: 100%;
    padding: 0 !important;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
}

.product-question-content h4 {
    margin-bottom: 6px;
    font-size: 15px;
}

.form-group-lg .form-control {
    height: 36px;
    font-size: 14px;
}

.form-group-lg .form-control.btn-primary {
    background: #FFC004;
    font-family: Montserrat;
    color: #000;
    font-weight: 700;
    border-radius: 20px;
    padding: 0;
    text-transform: uppercase;
    border: 0;
}

.form-group {
    min-height: 38px;
}

.product-question-unauthorized {
    font-size: 13px;
    margin: 0 15px;
}

.product-question-unauthorized a {
    font-weight: 700;
    color: #000;
}

.product__infos {
    background: transparent;
}

.product__infos--wrapper {
    padding: 0;
}

.product__infos--titles {
    font-family: Montserrat;
    font-weight: 700;
    border-bottom: 0;
}

.product__infos--title.active {
    color: #000;
}

.product__infos--title {
    opacity: 1;
}

.product-question-answer .panel-default{
    background-color: #eee;
    border: none;
}

.product-question-answer .panel-default .panel-body{
    background-color: #fff;
}

#product-new-question-form {
    padding-bottom: 80px;
}