.products-section-hd {
    font-family: Montserrat;
    font-weight: 600;
    color: #000;
    line-height: 1;
    display: table;
    background: #eee;
}

.products-section-hr {
    border-color: #ffc004;
}