footer {
    background: #000;
}

.footer-heading span {
    font-family: Montserrat;
    font-weight: 700;
    color: #ffc004;
}

.footer-menu-list-item-link {
    font-family: Montserrat;
    color: #fff;
    font-weight: 300;
}

.footer-bottom-area {
    background: #FFC004;
}

.footer-marketplace-info {
    font-family: Montserrat;
    font-weight: 300;
    color: #000;
}

.logos {
    font-size: 0;
    line-height: 0;
}

.logos a {
    line-height: 16px;
}

.logos a img {
    display: block;
}

.logos a:first-child {
    padding-right: 11px;
    margin-right: 11px;
    border-right: 1px solid #fff;
}

#ra-verified-seal {
    margin-top: 10px;
}