.pagination {
    width: auto;
    display: table;
    margin: 10px auto 30px;
}

.pagination > li > a,
.pagination > li > span,
.pagination > li.disabled span {
    font-family: Montserrat;
    color: #000;
    font-weight: 600;
    border: 0;
    padding: 6px 10px;
    background: transparent;
}

.pagination > li > a:focus,
.pagination > li > a:hover,
.pagination > li > span:focus,
.pagination > li > span:hover {
    color: #000;
}

.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
    background-color: #FFC004;
    border-color: #FFC004;
    color: #000;
    font-weight: 600;
    border-radius: 20px;
}

.pagination > li:first-child > a,
.pagination > li:first-child > span {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.pagination > li:last-child > a,
.pagination > li:last-child > span {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
