#product-shipping-panel.product-shipping-panel {
    width: 100%;
    border: 1px solid #f7d673;
    border-radius: 30px;
    padding: 9px 30px;
    max-width: 100%;
    margin-bottom: 10px;
}

.shipping-options-container-form{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.shipping-options-container-form .psp-title{
    width: 55%;
}

.product-shipping-panel .input-group .form-control{
    max-width: 186px;
    border-radius: 20px 0 0 20px;
    border-right: 0;
}

.product-shipping-panel .input-group .btn-default{
    background-color: #fff;
    border: 1px solid #ccc;
    border-left: 0;
    color: #2a2a2a;
    border-radius: 0 20px 20px 0;
}

#shipping-options-list .psp-options-table .psp-option-row{
    background-color: white;
    border-bottom: solid 10px #eeeeee;
}

#shipping-options-list .psp-options-header {
    flex-direction: column;
    height: 75px;
}



@media only screen and (min-width: 1024px){
    #shipping-options-list .psp-options-header {
        flex-direction: row;
        height: auto;
    }

    .shipping-options-container-form .psp-form-panel{
        margin-top: 0;
    }

    .shipping-options-container-form .psp-title{
        font-size: 16px;
        width: 25%;
    }
    
    .shipping-options-container-form{
        justify-content: center;
    }

    .shipping-options-container-form .input-group .form-control{
        max-width: 245px;
    }
}