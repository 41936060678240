.filter {
    margin-bottom: 0;
    padding: 4px 0;
}

.filter-option {
    max-height: 36px;
}

.filter-option--active {
    max-height: 1500px;
}

.filter-option__name,
.filter-option--active .filter-option__name,
.filter-option--active {
    box-shadow: none !important;
}

.filter-option__name {
    font-family: Montserrat;
    font-size: 17px;
    color: #000;
    font-weight: 600;
    line-height: 33px;
    height: 36px;
    border-bottom: 3px solid transparent;
    position: relative;
    padding: 0 0 0 28px;
}

.filter-option__name:before {
    content: '';
    display: block;
    width: 73px;
    height: 3px;
    position: absolute;
    background: #000;
    bottom: -7px;
    left: 2px;
}

.filter-option--has-dropdown::after {
    width: 18px;
    height: 18px;
    background: #FFC004;
    border-radius: 50%;
    padding: 0;
    line-height: 17px;
    color: #fff;
    text-align: center;
    font-size: 14px;
    font-weight: 700;
    top: 50%;
    left: 0;
    right: unset;
    bottom: unset;
    transform: translateY(-50%);
}

.filter-values {
    border: 0;
    margin: 0;
    margin-bottom: 2px;
    padding: 10px 0;
}

.filter-value {
    margin: 6px 0;
}

.filter-value__link {
    font-family: Montserrat;
    font-weight: 300;
    color: #000 !important;
}

.filter-value__link::before {
    border-color: #dadada;
    width: 18px;
    height: 18px;
}

.filter-value__link:hover::before,
.filter-value--selected .filter-value__link:hover::before {
    color: #FFC004;
}

.filter-size-value_item {
    border: 1px solid #dadada;
    padding: 3px 7px;
    margin: 6px 1px;
    color: #000;
}

.filter-size-value_item:hover, .filter-size-value--selected {
    background: #FFC004;
    border-color: #FFC004;
}

.filter__clear-all {
    margin: 0 0 5px;
}
